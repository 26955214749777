
















import Sidebar from "@/components/Sidebar.vue";
import {
  computed,
  defineComponent,
  provide,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import Tapbar from "@/components/Tapbar.vue";
import TestUserData from "./Components/TestUserData.vue";
import TestUserCreated from "./Components/TestUserCreated.vue";
import ITabContent from "@/interfaces/ITabContent";
import CreateWizard from "@/components/CreateWizard.vue";
import IStep from "@/interfaces/IStep";
import { User } from "@/graphql/types";
import useVuelidate from "@vuelidate/core";
import useProfile from "@/composables/useProfile";
import { EditableKey } from "@/symbols";
import { TestUserKey } from "./symbols";
import useCancelButton from "@/composables/buttons/useCancelButton";
import useGoToParent from "@/composables/useGoToParent";
import useCreateTestUserMutation from "./Composables/useCreateTestUserMutation";

export default defineComponent({
  props: {
    id: {
      type: String,
      required: false,
    },
  },
  components: { Sidebar, Tapbar, CreateWizard },
  setup(props, { root }) {
    const { isCentralAdmin } = useProfile();
    const editable = computed(() => isCentralAdmin.value ?? false);

    const isNew = ref(true);
    const currentTab = ref(0);
    const hasChanges = ref<boolean | null>(null);
    if (isNew.value) hasChanges.value = false;
    const v$ = useVuelidate({ $autoDirty: true, $lazy: true });

    const user = reactive<User>({
      id: "",
      firstname: "",
      lastname: "",
      email: "",
      description: "",
      organization: {},
      roleAssignments: [],
      applicationAssignments: [],
    });

    const createMutation = useCreateTestUserMutation(user);
    provide(EditableKey, editable);

    createMutation.onDone(({ data }) => {
      user.id = data?.userMutation?.createTestUser?.id ?? "";
      currentTab.value++;
    });

    provide(TestUserKey, user);

    watch(
      () => [
        user.firstname,
        user.lastname,
        user.email,
        user.firstname,
        user.organization?.id,
      ],
      () => {
        if (hasChanges.value == null) {
          hasChanges.value = false;
        } else {
          hasChanges.value = true;
        }
      },
      { deep: true }
    );
    return {
      isNew,
      currentTab,
      hasChanges,
      user,
      v$,
      loading: computed(() => createMutation.loading.value),
      tabs: computed<ITabContent[]>(() => [
        {
          title: root.$tc("common.steps.data"),
          primaryButton: {
            title: root.$tc("test_users.create"),
            variant: "success",
            onClick: async () => {
              if (await v$.value.$validate()) {
                createMutation.call();
              }
            },
            disabled:
              !editable.value ||
              (!hasChanges.value ?? false) ||
              v$.value.$pending ||
              v$.value.$errors.length > 0,
          },
          secondaryButton: useCancelButton(() => useGoToParent(root.$router)),
          component: TestUserData,
        },
        {
          title: root.$tc("common.steps.done"),
          component: TestUserCreated,
        },
      ]),
      steps: computed<IStep[]>(() => [
        {
          title: root.$tc("common.steps.data"),
          icon: "building",
        },
        {
          title: root.$tc("common.steps.done"),
          icon: "check",
        },
      ]),
    };
  },
});
