


































import InputGroup from "@/components/InputGroup.vue";
import {
  maxLength,
  required,
  email,
  withI18nMessage,
} from "@/utilities/validators";
import { helpers } from "@vuelidate/validators";
import { defineComponent, toRefs, toRef } from "@vue/composition-api";
import useVuelidate from "@vuelidate/core";
import OrganizationSelector from "@/components/Selectors/OrganizationSelector.vue";
import { TestUserKey } from "../symbols";
import injectStrict from "@/utilities/injectstrict";
import { usePromisable } from "@/utilities/useLazyApolloQuery";
import { useGetUserByEmailLazyQuery } from "@/graphql/types";
const { withAsync } = helpers;

export default defineComponent({
  components: { InputGroup, OrganizationSelector },
  setup() {
    const user = injectStrict(TestUserKey);
    const v$ = useVuelidate(useValidations(uniqueEmailValidator), {
      ...toRefs(user),
      organization: toRef(user, "organization"),
    });

    const { fetch: fetchUniqueName } = usePromisable(
      useGetUserByEmailLazyQuery(() => ({
        email: user.email ?? "",
      }))
    );

    async function uniqueEmailValidator() {
      if (!v$.value.email.$dirty || user.email == "") return true;
      var result = await fetchUniqueName();
      if (result && result.data && result.data.userByEmail != null)
        return false;
      return true;
    }
    return {
      v$,
      user,
    };
  },
});

function useValidations(uniqueEmailValidator: () => Promise<boolean>) {
  return {
    firstname: { required, maxLength: maxLength(255) },
    lastname: { required, maxLength: maxLength(255) },
    email: {
      required,
      email,
      uniqueEmail: withAsync(withI18nMessage(uniqueEmailValidator)),
    },
    organization: {},
  };
}
