import { User, useCreateTestUserMutation } from "@/graphql/types";
import injectStrict from "@/utilities/injectstrict";
import { ParentQueryKey } from "@/views/Users/symbols";

export default function (user: User) {
  const mutation = useCreateTestUserMutation({});
  const parentQuery = injectStrict(ParentQueryKey);

  function call() {
    mutation.mutate(
      {
        input: {
          email: user.email || "",
          organizationId: user.organization?.id,
          firstname: user.firstname || "",
          lastname: user.lastname || "",
        },
      },
      {
        context: {
          useMultipart: true,
        },
        update: (cache, { data: data }) => {
          const cachedData = cache.readQuery(parentQuery);
          cache.writeQuery({
            ...parentQuery,
            data: {
              ...cachedData,
              users: {
                ...cachedData?.users,
                totalCount: (cachedData?.users?.totalCount ?? 0) + 1,
                nodes: [
                  ...(cachedData?.users?.nodes ?? []),
                  data?.userMutation?.createTestUser,
                ],
              },
            },
          });
        },
      }
    );
  }

  return {
    ...mutation,
    call,
  };
}
